import Image, { StaticImageData } from 'next/image'
import { useMediaQuery } from 'react-responsive'
import style from './PartnerCard.module.scss'

interface IPartnerCard {
  image: StaticImageData
  name: string
  link?: [string, string]
}

function PartnerCard({ image, name, link }: IPartnerCard) {
  const title = `파트너사 - ${name}`
  const isMobile = useMediaQuery({ maxWidth: 1193 })

  const onClickLink = () => {
    if (link) {
      window.open(isMobile ? link[1] : link[0], '_blank')
    }
  }

  return (
    <div className={style.container}>
      <button
        type='button'
        title={title}
        aria-label={title}
        style={{ cursor: link ? 'pointer' : 'default' }}
        onClick={onClickLink}
      >
        <div>
          <Image src={image} alt={title} />
        </div>
        <p>
          <span>{name}</span>
        </p>
      </button>
    </div>
  )
}

export default PartnerCard

export const HOME_PATH = '/'
export const SERVICE_PATH = '/service'
export const CONTACT_PATH = '/contact'
export const CAREER_PATH = '/career'

// event
export const OWNER_EVENT = '/event/owner'
export const DRIVER_EVENT = '/event/driver'

// article
export const CONTENTS_PATH = '/contents'
export const CONTENTS_DETAIL_PATH = '/contents/[id]'

// interview
export const INTERVIEW_PATH = '/interview'
export const INTERVIEW_DETAIL_PATH = '/interview/[id]'

import MobileDetect from 'mobile-detect'
import { useCustomRouter } from '@/features/common/hooks/useCustomRouter'
import { sendCustomEvent } from '@/utils/GTM'

export function useDeepLink(deepLinkOption?: DeepLinkOption): UseDeepLink {
  const option: Required<DeepLinkOption> = {
    fallback: 'REGIST',
    ...deepLinkOption,
  }
  const { handleExternalLink } = useCustomRouter()
  const linkAndroid = process.env.NEXT_PUBLIC_GOOGLEPLAY_APP_URL ?? ''
  const linkIOS = process.env.NEXT_PUBLIC_APPSTORE_APP_URL ?? ''
  const linkFallback = process.env.NEXT_PUBLIC_OWNER_URL ?? '' // 화주웹 로그인
  const linkAccountRegistration = `${linkFallback}/account/registration` // 화주웹 회원가입하기

  // 화주 안드로이드앱 다운로드를 위한 플레이스토어 이동
  const linkToPlayStore = () => {
    sendCustomEvent({
      event: 'click',
      category: '앱 다운로드 링크 클릭',
      label: '화주 Android 앱 다운로드',
      value: linkAndroid,
    })
    handleExternalLink(linkAndroid)
  }

  // 화주 iOS앱 다운로드를 위한 앱스토어 이동
  const linkToAppStore = () => {
    sendCustomEvent({
      event: 'click',
      category: '앱 다운로드 링크 클릭',
      label: '화주 iOS 앱 다운로드',
      value: linkIOS,
    })
    handleExternalLink(linkIOS)
  }

  // 화주웹 회원가입하기 이동
  const linkToAccountRegistration = () => {
    sendCustomEvent({
      event: 'click',
      category: '외부 링크 이동 클릭',
      label: '화주 이벤트 - 가입하러가기',
    })
    handleExternalLink(linkAccountRegistration)
  }

  // 모바일 앱 다운로드를 위한 디바이스별 분기 혹은 모바일 디바이스 외의 경우 화주웹 등으로 이동
  const launchAppOrStore = () => {
    const md = new MobileDetect(window.navigator.userAgent, 1193)

    switch (md.os()) {
      case 'AndroidOS':
        linkToPlayStore()
        break
      case 'iOS':
        linkToAppStore()
        break
      default: {
        let link: string
        let label: string
        if (option.fallback === 'REGIST') {
          link = linkAccountRegistration
          label = '화주 웹 바로가기 - 더 운반 가입하기'
        } else if (option.fallback === 'QUICK') {
          link = linkFallback
          label = '화주 웹 바로가기 - 퀵 링크'
        } else {
          link = linkFallback
          label = '화주 웹 바로가기'
        }
        sendCustomEvent({ event: 'click', category: '외부 링크 이동 클릭', label, value: link })
        handleExternalLink(link)
        break
      }
    }
  }

  return { linkToPlayStore, linkToAppStore, launchAppOrStore, linkToAccountRegistration }
}
